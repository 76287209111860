import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";

import WorldMapChart from "./atoms/WorldMapChart";

const PublicMap = ({ countries }) => {
  console.log('[Public Map] countries', countries)
  const [countryContent, setCountryContent] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setShowTooltip(true);
  });
  return (
    <div style={{ width: "100%" }}>
      <WorldMapChart
        countries={countries}
        setTooltipContent={setCountryContent}
        tooltipContent={countryContent}
      />
      {showTooltip && <ReactTooltip>{countryContent}</ReactTooltip>}
    </div>
  );
};

export default PublicMap;
